const Announcements = [
  {
    title: "2024-2025",
    content: [
      {
        name: "Audited Financial Results for the year ended 31st March 2024",
        link: "/vmarc/Announcements/2024-25/Audited_Financial_Results_for_the_year_ended_31st_March_2024%20__.pdf",
      },
      {
        name: "Intimation of Board Meeting for Approval of Financial Results F.Y 2023-24",
        link: "/vmarc/Announcements/2024-25/Intimation%20of%20Board%20Meeting.pdf",
      },
      {
        name: "Resignation of Chief Financial Officer",
        link: "/vmarc/Announcements/2024-25/Resignation_of_Chief_Financial_Officer_wef_09.04.2024.pdf",
      },
    ],
  },
  {
    title: "2023-2024",
    content: [
      {
        name: "Trading Window Closure for the period ended 31.03.2024",
        link: "/vmarc/Announcements/2023-24/Trading_Window_Closure_for_the_period_ended_31.03.2024.pdf",
      },
      {
        name: "Intimation about execution of Memorandum of Understanding (MoU)",
        link: "/vmarc/Announcements/2023-24/Intimation_about_execution_of_Memorandum_of_Understanding__MOU_.pdf",
      },
      {
        name: "Disclosure Update: New Product – Medium Voltage Covered Conductor (MVCC)",
        link: "/vmarc/Announcements/2023-24/Disclosure_-Update_-MVCC_New_Product.pdf",
      },
      {
        name: "Credit Rating",
        link: "/vmarc/Announcements/2023-24/Credit_Rating.pdf",
      },
      {
        name: "Reply to Clarification NSE Letter Dated 02.03.2024",
        link: "/vmarc/Announcements/2023-24/VIL%20Clarification.pdf",
      },
      {
        name: "Disclosures under Reg 30 of SEBI (LODR)",
        link: "/vmarc/Announcements/2023-24/VIL%2029.02.2024.pdf",
      },
      {
        name: "Appointment of Company Secretary & Compliance Officer",
        link: "/vmarc/Announcements/2023-24/Appointment_of_Company_Secretary___Compliance_Officer.pdf",
      },
      {
        name: "Audited Financial Results for the year ended March 31, 2023",
        link: "/vmarc/Announcements/2023-24/Audited_Financial_Results_for_the_year_ended_31st_March_2023.pdf",
      },
      {
        name: "Intimation of Board Meeting for Approval of Financial Results FY 2022-23",
        link: "/vmarc/Announcements/2023-24/Intimation%20of%20Board%20Meeting%20for%20Approval%20of%20Financial%20Results_0.pdf",
      },
      {
        name: "Outcome of Board Meeting",
        link: "/vmarc/Announcements/2023-24/Outcome_of_Board_Meeting_held_on_26.04.2023_0.pdf",
      },
      {
        name: "Appointment of Additional Non-Executive Independent Director",
        link: "/vmarc/Announcements/2023-24/Appointment_of_Additional_Non-Executive_Independent_Director.pdf",
      },
      {
        name: "Resignation of Independent Director",
        link: "/vmarc/Announcements/2023-24/Resignation_of_Independent_Director.pdf",
      },
      {
        name: "Resignation of Company Secretary & Compliance Officer",
        link: "/vmarc/Announcements/2023-24/Resignation_of_Company_Secretary___Compliance_Officer.pdf",
      },
      {
        name: "Resignation of Chief Financial Officer",
        link: "/vmarc/Announcements/2023-24/Resignation%20of%20Chief%20Financial%20Officer.pdf",
      },
      {
        name: "Appointment of Chief Financial Officer",
        link: "/vmarc/Announcements/2023-24/Appointment%20of%20Chief%20Financial%20Officer.pdf",
      },
      {
        name: "Notice of 10th AGM",
        link: "/vmarc/Announcements/2023-24/Notice_of_10th_AGM.pdf",
      },
      {
        name: "Annual Report 2022-23",
        link: "/vmarc/Announcements/2023-24/V-Marc_India_Limited_-_Annual_Report_22-23.pdf",
      },
      {
        name: "Proceedings of 10th Annual General Meeting ",
        link: "/vmarc/Announcements/2023-24/VIL%20-%2010th%20AGM.pdf",
      },
      {
        name: "10th AGM Voting Results",
        link: "/vmarc/Announcements/2023-24/10th%20AGM%20Voting%20Results.pdf",
      },
      {
        name: "Scrutinizer's Report of 10th AGM",
        link: "/vmarc/Announcements/2023-24/Scrutinizer%27s%20Report%20of%2010th%20AGM.pdf",
      },
    ],
  },
  {
    title: "2022-2023",
    content: [
      {
        name: "Trading Window Closure__",
        link: "/vmarc/Announcements/2022-23/ClosureofTradingWindow_3.pdf",
      },
      {
        name: "Intimation of Board Meeting for Approval of Financial Results F.Y 2021-22",
        link: "/vmarc/Announcements/2022-23/Intimation_of_Board_Meeting_for_Approval_of_Financial_Results_F.Y_2021-22.pdf",
      },
      {
        name: "Audited Financial Results for the year ended 31st March 2022",
        link: "/vmarc/Announcements/2022-23/Audited_Financial_Results_for_the_year_ended_31st_March__2022.pdf",
      },
      {
        name: "Disclosure under Regulation 31 of SEBI (SAST) Regulations, 2011",
        link: "/vmarc/Announcements/2022-23/Disclosure-under-31.pdf",
      },
      {
        name: "Disclosure under Regulation 29 of SEBI (SAST) Regulations, 2011",
        link: "/vmarc/Announcements/2022-23/Disclosure-Regulation-29.pdf",
      },
      {
        name: "Resignation of Company Secretary",
        link: "/vmarc/Announcements/2022-23/Disclosure-Resignation-CS.pdf",
      },
      {
        name: "Annual Report 2021-22",
        link: "/vmarc/Announcements/2022-23/Annual-Report-2021-22.pdf",
      },
      {
        name: "Notice of 9th AGM",
        link: "/vmarc/Announcements/2022-23/Notice-of-9th-AGM.pdf",
      },
      {
        name: "Scrutinizers' Report",
        link: "/vmarc/Announcements/2022-23/Dislcoure_of_Scrutinizer_report.pdf",
      },
      {
        name: "Voting Results",
        link: "/vmarc/Announcements/2022-23/Disclosure_of_Voting_Result.pdf",
      },
      {
        name: "Nine'th Annual General Meeting",
        link: "https://youtu.be/SkhShxaMJFM?si=ieMnqG3c2EXTxvkm",
      },
      {
        name: "Resignation of Aloak Kumar Tulsiyan",
        link: "/vmarc/Announcements/2022-23/Disclosure_of_Resignation-Signed_0.pdf",
      },
      {
        name: "Appointment of Mr. Deepak Prabhakar Tikle",
        link: "/vmarc/Announcements/2022-23/Appointment_of_Director_0.pdf",
      },
      {
        name: "Reconstitution of Audit Committe and CSR Committee",
        link: "/vmarc/Announcements/2022-23/Reconstitution_of_Audit_and_CSR_Committee.pdf",
      },
      {
        name: "Outcome of Board Meeting",
        link: "/vmarc/Announcements/2022-23/Outcome_of_Board_Meeting_Signed.pdf",
      },
    ],
  },
  {
    title: "2021-2022",
    content: [
      {
        name: "INvestor Presentation",
        link: "/vmarc/Announcements/2021-22/Investor_Presentation.pdf",
      },
      {
        name: "Disclosure for Revision in credit ratings",
        link: "/vmarc/Announcements/2021-22/Disclosure_for_Revision_In_Credit_Ratings.pdf",
      },
      {
        name: "Authorized KMP for determining materiality",
        link: "/vmarc/Announcements/2021-22/Authorized_KMP_for_determining_materiality.pdf",
      },
      {
        name: "Appointment of Internal Auditor",
        link: "/vmarc/Announcements/2021-22/Appointment%20of%20Internal%20Auditor.pdf",
      },
      {
        name: "Notice of Board Meeting",
        link: "/vmarc/Announcements/2021-22/notice-of-board-meeting.pdf",
      },
      {
        name: "Financial Result As Per Regulation 33",
        link: "/vmarc/Announcements/2021-22/Financial%20Result%20As%20Per%20Regulation%2033_0.pdf",
      },
      {
        name: "Appointment of Secretarial Auditor",
        link: "/vmarc/Announcements/2021-22/6_Appointment%20of%20Secretarial%20Auditor.pdf",
      },
      {
        name: "Intimation of Board Meeting to be held on 31.08.2021",
        link: "/vmarc/Announcements/2021-22/7%20Intimation%20of%20Board%20Meeting%20to%20be%20held%20on%2031.08.2021.pdf",
      },
      {
        name: "Annual Report",
        link: "/vmarc/Announcements/2021-22/10%20Annual%20Report.pdf",
      },
      {
        name: "Notice of 8th Annual General Meeting",
        link: "/vmarc/Announcements/2021-22/11%20Notice%20of%208th%20%20Annual%20General%20Meeting.pdf",
      },
      {
        name: "Nomination-form-Shareholders",
        link: "/vmarc/Announcements/2021-22/12%20Nomination-form-Shareholders.pdf",
      },
      {
        name: "Eighth Annual General Meeting",
        link: "https://youtu.be/J8kTGW1XfN8?si=A58pyBAO-jYMAy-2",
      },
      {
        name: "Proceeding of the AGM",
        link: "/vmarc/Announcements/2021-22/13%20Proceeding%20of%20the%20AGM.pdf",
      },
      {
        name: "Scrutiniser Report",
        link: "/vmarc/Announcements/2021-22/14%20Scrutiniser%20Report.pdf",
      },
      {
        name: "Voting Results",
        link: "/vmarc/Announcements/2021-22/Disclosure_of_Voting_Results.pdf",
      },
      {
        name: "Outcome of Board Meeting",
        link: "/vmarc/Announcements/2021-22/8%20Outcome%20of%20Board%20Meeting.pdf",
      },
      {
        name: "Intimation of Board Meeting to be held on 12.11.2021",
        link: "/vmarc/Announcements/2021-22/16%20Intimation%20of%20Board%20Meeting%20to%20be%20held%20on%2012.11.2021.pdf",
      },
      {
        name: "Financial Results as on 30.09.2021 as per Regulation 33",
        link: "/vmarc/Announcements/2021-22/17%20Financial%20Results%20as%20on%2030.09.2021%20as%20per%20Regulation%2033.pdf",
      },
      {
        name: "Statement of Deviation of funds",
        link: "/vmarc/Announcements/2021-22/18%20%20Statement%20of%20Deviation%20of%20funds.pdf",
      },
    ],
  },
];

export { Announcements };

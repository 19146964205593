const Policies = {
  content: [
    {
      name: "Whistle Blower Policy and Vigil Mechanism",
      link: "/vmarc/Policies/012.pdf",
    },
    {
      name: "Terms and Conditions of Appointment of IDs",
      link: "/vmarc/Policies/011.pdf",
    },
    {
      name: "Code of conduct to regulate, monitor and report trading by designated persons",
      link: "/vmarc/Policies/001.pdf",
    },
    {
      name: "Code of ethics for directors, senior management and other employee",
      link: "/vmarc/Policies/002.pdf",
    },
    {
      name: "Code of practices and procedures for fair disclosure of unpublished price sensitive information",
      link: "/vmarc/Policies/003.pdf",
    },
    {
      name: "Policy for determining material subsidiaries",
      link: "/vmarc/Policies/004.pdf",
    },
    {
      name: "Policy for determination of materiality",
      link: "/vmarc/Policies/005.pdf",
    },
    {
      name: "Policy on CSR - V marc India Limited",
      link: "/vmarc/Policies/006.pdf",
    },
    {
      name: "Policy on dealing with related party transactions",
      link: "/vmarc/Policies/007.pdf",
    },
    {
      name: "Details of familiarisation programmes of v-marc india limited for its independent directors",
      link: "/vmarc/Policies/008.pdf",
    },
    {
      name: "Board remuneration policy",
      link: "/vmarc/Policies/009.pdf",
    },
    {
      name: "Archival policy",
      link: "/vmarc/Policies/010.pdf",
    },
  ],
};
export { Policies };
